// Util
import { ValidationUtil } from '@/utils/validationUtil';

// Others
import _ from 'lodash';


export const DispatchUtil = {

	getCompanyIdForUserOptions(source, destination) {
		if (source.isActive === 'true') {
			return source.id;
		} else if (source.isActive === 'false' && destination.isActive === 'true') {
			return destination.id;
		}
		return null;
	},

	cleanupFields(dispatch) {
		let cleanedDispatch = Object.assign({}, dispatch);

		delete cleanedDispatch['Source'];
        delete cleanedDispatch['Destination'];
        delete cleanedDispatch['newCompanyToAdd'];
        delete cleanedDispatch['newTransportationToAdd'];
        delete cleanedDispatch['Truck Driver'];
        delete cleanedDispatch['Truck Assistant'];
		delete cleanedDispatch['Date Created'];
		delete cleanedDispatch['Date Deployed'];
		delete cleanedDispatch['Date Cancelled'];
		delete cleanedDispatch['Date Updated'];
		delete cleanedDispatch['_showDetails'];

		return cleanedDispatch;
	},

	validate(dispatch, param, vm) {
		let isValid = true;

		let companiesObj = param.companiesObj;
		let storageLocationsObj = param.storageLocationsObj;
		let connectionsObj = param.connectionsObj;
		let assetsObj = param.assetsObj;

		let dateDeployed = dispatch.dateDeployed;
		let dateReceived = dispatch.dateReceived;
		let sourceCompany = dispatch.source.company;
		let sourceLoc = dispatch.source.storageLocation;
		let destinationCompany = dispatch.destination.company;
		let destinationLoc = dispatch.destination.storageLocation;
		let status = dispatch.status;
		let transportationCompany = dispatch.transportation.company;
		let driver = dispatch.driver.name;
		let driverLicense = dispatch.driver.licenseUrl;
		let assistants = dispatch.driver.assistants;
		let plateNo = dispatch.transportation.plateNo;
		let assets = dispatch.assets;


		// Dispatch Date
		if (!dateDeployed && dateDeployed.length === 0) {
			vm.$toaster.warning('Date Deployed is required.');
			return false;
		} else if (!ValidationUtil.isDate(dateDeployed)) {
			vm.$toaster.warning(
				'Invalid date deployed. Follow the format mm-dd-yyyy.'
			);
			return false;
		} else if (status === 'Received' && !dateReceived && dateReceived.length === 0) {
			vm.$toaster.warning('Date Received is required for Received dispatch.');
			return false;
		} else if (status === 'Received' && dateReceived && dateReceived.length > 0 && !ValidationUtil.isDate(dateReceived)) {
			vm.$toaster.warning(
				'Invalid date received. Follow the format mm-dd-yyyy.'
			);
			return false;
		}


		// Status
		else if (!status && status.length === 0) {
			vm.$toaster.warning('Status is required.');
			return false;
		} else if (
			status !== 'In-Transit' &&
			status !== 'Received'
		) {
			vm.$toaster.warning('Invalid dispatch status.');
			return false;
		}


		// Source and Destination
		else if (!sourceCompany || sourceCompany.length === 0) {
			vm.$toaster.warning('Source Company is required.');
			return false;
		} else if (!sourceLoc || sourceLoc.length === 0) {
			vm.$toaster.warning('Source Storage Location is required.');
			return false;
		} else if (!destinationCompany || destinationCompany.length === 0) {
			vm.$toaster.warning('Destination Company is required.');
			return false;
		} else if (!destinationLoc || destinationLoc.length === 0) {
			vm.$toaster.warning('Destination Storage Location is required.');
			return false;
		} else if (!companiesObj[sourceCompany]) {
			vm.$toaster.warning(
				'Source Company ' + sourceCompany + ' does not exists.'
			);
			return false;
		} else if (!companiesObj[destinationCompany]) {
			vm.$toaster.warning(
				'Destination Company ' + destinationCompany + ' does not exists.'
			);
			return false;
		} else if (!storageLocationsObj[sourceLoc]) {
			vm.$toaster.warning(
				'Source Storage Location ' + sourceLoc + ' does not exists.'
			);
			return false;
		} else if (!storageLocationsObj[destinationLoc]) {
			vm.$toaster.warning(
				'Destination Storage Location ' + destinationLoc + ' does not exists.'
			);
			return false;
		} else if (
			sourceCompany === destinationCompany &&
			sourceLoc === destinationLoc
		) {
			vm.$toaster.warning('Source and Destination should not be the same.');
			return;
		} else if (
			sourceCompany !== destinationCompany &&
			(!connectionsObj[sourceCompany] ||
				!connectionsObj[sourceCompany].includes(destinationCompany))
		) {
			vm.$toaster.warning(
				`There is no existing connection/s between ${sourceCompany} and ${destinationCompany}.`
			);
			return false;
		}

		// Driver
		else if (!driver && driver.length === 0) {
			vm.$toaster.warning('Driver is required.');
			return false;
		} else if (!driverLicense && driverLicense.length === 0) {
			vm.$toaster.warning('Driver license is required.');
		}

		// Transportation
		else if (!transportationCompany && transportationCompany.length === 0) {
			vm.$toaster.warning('Transportation is required.');
			return false;
		} else if (!plateNo && plateNo.length === 0) {
			vm.$toaster.warning('Plate no is required.');
			return false;
		} else if (!ValidationUtil.isAlphaWithWhiteSpace(driver)) {
			vm.$toaster.warning('Invalid driver name.');
			return false;
		} else if (
			assistants.length > 0 &&
			!ValidationUtil.isAlphaWithWhiteSpaceAndComma(assistants)
		) {
			vm.$toaster.warning('Invalid assistant(s) name.');
			return false;
		} else if (!ValidationUtil.isValidPlateNo(plateNo)) {
			vm.$toaster.warning('Invalid plate no.');
			return false;
		} else if (!companiesObj[transportationCompany]) {
			vm.$toaster.warning(
				`Transportation Company${transportationCompany} does not exists.`
			);
			return false;
		}


		// Assets
		if (_.isEmpty(assets)) {
			vm.$toaster.warning(
				'Dispatch should have at least 1 asset to import.'
			);
			return false;
		} else if (ValidationUtil.hasInvalidAsset(assets)) {
			vm.$toaster.warning(
				'At least 1 invalid asset code is not allowed.'
			);
			return false;
		} else if (_.size(assetsObj) < _.size(assets)) {
			vm.$toaster.warning(
				'At least 1 non-existent asset is not allowed.'
			);
			return false;
		} else if (ValidationUtil.hasAssetWithInTransitStatus(assets, assetsObj)) {
			let assetsInTransit = ValidationUtil.getAssetWithInTransitStatus(
				assets,
				assetsObj
			);
			vm.$toaster.warning(
				'Importing assets with in-transit status is not allowed. Please check the following asset/s: ' +
				assetsInTransit
			);
			return false;
		}

		// Check if all assets has corresponding Asset Type Code
		// For now, only allow the asset codes with corresponding asset type code 

		return isValid;
	},

	isInactiveCompany(company) {
		return company.isActive === 'false' ? 'true' : 'false';
	},

	isValidTransportation(transportation) {
		let { company, plateNo } = transportation;

		if (!_.isEmpty(transportation)) {
			return !(!company || !plateNo);
		}

		return true;
	},

	getCompanyLocationDisplay(item) {
		return item.company + ' (' + item.storageLocation + ')';
	},

	getDriverLicenseObj(driver) {
		const driversLicense = !_.isEmpty(driver.licenseUrl)
			? {
				url: driver.licenseUrl,
				_isNew: false,
			}
			: {};
		return JSON.parse(JSON.stringify(driversLicense));
	}

}
