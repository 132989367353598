<template>
	<b-modal
		id="print-dispatch-summary"
		:title="title"
		size="xl"
		ok-title="Download"
		ref="modal"
		@ok="handleDownload"
		:cancel-disabled="disableConfirmButtons"
		:ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true"
	>
		<loading
			:active.sync="isLoading"
			loader="spinner"
			color="#20A8D8"
			:is-full-page="false"
		/>

		<b-container ref="dispatchSummary" v-show="selDispatch !== null">
			<b-row class="my-2">
				<b-col sm="6">
					<div class="dispatch-header">Delivery Report</div>
					<div class="dispatch-id">
						{{ selDispatch.dispatchId }}
					</div>
					<div class="dispatch-date">
						{{ showFormattedDate(selDispatch.dateCreated) }}
					</div>
				</b-col>
				<b-col sm="6" align="right">
					<img
						v-img-orientation-changer
						src="img/logo.png"
						class="img-logo"
						alt="Ayun! Logo"
					/>
				</b-col>
			</b-row>

			<b-row class="my-4">
				<b-col sm="6">
					<b-row no-gutters>
						<b-col sm="2" class="section-header"> Truck </b-col>
						<b-col sm="10"><hr /></b-col>
					</b-row>

					<b-row class="mt-0">
						<b-col sm="5" class="form-field"> Plate No/CR/OR: </b-col>
						<b-col sm="7" class="form-value">{{
							selDispatch.transportation
								? selDispatch.transportation.plateNo
								: ''
						}}</b-col>
					</b-row>

					<b-row class="mt-0">
						<b-col sm="5" class="form-field"> Truck Company: </b-col>
						<b-col sm="7" class="form-value">{{
							selDispatch.transportation
								? selDispatch.transportation.company
								: ''
						}}</b-col>
					</b-row>

					<b-row class="mt-0">
						<b-col sm="5" class="form-field"> Driver: </b-col>
						<b-col sm="7" class="form-value">{{
							selDispatch.driver
								? selDispatch.driver.name
								: ''
						}}</b-col>
					</b-row>

					<b-row class="mt-0">
						<b-col sm="5" class="form-field"> Assistant(s): </b-col>
						<b-col sm="7" class="form-value">{{
							selDispatch.driver
								? selDispatch.driver.assistants
								: ''
						}}</b-col>
					</b-row>
				</b-col>

				<b-col sm="6">
					<b-row no-gutters>
						<b-col sm="5" class="section-header"> Scan to view in App </b-col>
						<b-col sm="7"><hr /></b-col>
					</b-row>

					<b-row>
						<b-col sm="5" class="form-field"> QR CODE: </b-col>
						<b-col sm="7" class="form-value">
							<qr-code size="100" :text="selDispatch.dispatchId" />	
						</b-col>
					</b-row>
				</b-col>
			</b-row>

			<b-row class="my-4">
				<b-col sm="6">
					<b-row no-gutters>
						<b-col sm="2" class="section-header"> Source </b-col>
						<b-col sm="10"><hr /></b-col>
					</b-row>

					<b-row>
						<b-col sm="5" class="form-field"> Company: </b-col>
						<b-col sm="7" class="form-value">{{
							selDispatch.source ? selDispatch.source.company : ''
						}}</b-col>
					</b-row>
					<b-row class="mt-0">
						<b-col sm="5" class="form-field"> Warehouse: </b-col>
						<b-col sm="7" class="form-value">{{
							selDispatch.source ? selDispatch.source.storageLocation : ''
						}}</b-col>
					</b-row>
					<b-row class="mt-0">
						<b-col sm="5" class="form-field"> Address: </b-col>
						<b-col sm="7" class="form-value">{{
							sourceLocation.address
						}}</b-col>
					</b-row>
					
					<b-row class="mt-0">
						<b-col sm="5" class="form-field"> Date Dispatched: </b-col>
						<b-col sm="7" class="form-value">{{
							showFormattedDate(selDispatch.dateDeployed)
						}}</b-col>
					</b-row>
					
					<b-row class="mt-0">
						<b-col sm="5" class="form-field"> Status: </b-col>
						<b-col sm="7" class="form-value">{{ selDispatch.status }}</b-col>
					</b-row>

				</b-col>

				<b-col sm="6">
					<b-row no-gutters>
						<b-col sm="3" class="section-header"> Destination </b-col>
						<b-col sm="9"><hr /></b-col>
					</b-row>

					<b-row>
						<b-col sm="5" class="form-field"> Company: </b-col>
						<b-col sm="7" class="form-value">{{
							selDispatch.destination ? selDispatch.destination.company : ''
						}}</b-col>
					</b-row>
					<b-row class="mt-0">
						<b-col sm="5" class="form-field"> Warehouse: </b-col>
						<b-col sm="7" class="form-value">{{
							selDispatch.destination
								? selDispatch.destination.storageLocation
								: ''
						}}</b-col>
					</b-row>
					<b-row class="mt-0">
						<b-col sm="5" class="form-field"> Address: </b-col>
						<b-col sm="7" class="form-value">{{
							destinationLocation.address
						}}</b-col>
					</b-row>
					
					<b-row class="mt-0">
						<b-col sm="5" class="form-field"> Date Received: </b-col>
						<b-col sm="7" class="form-value">{{
							showFormattedDate(selDispatch.dateReceived)
						}}</b-col>
					</b-row>
					
					<b-row class="mt-0">
						<b-col sm="5" class="form-field"> Status: </b-col>
						<b-col sm="7" class="form-value">{{ selDispatch.status }}</b-col>
					</b-row>

				</b-col>
			</b-row>

			<b-row class="my-5">
				<b-col sm="12">
					<table>
						<tr>
							<th colspan="2">Dispatch Details</th>
							<th colspan="2">Receipt Details</th>
						</tr>
						<tr>
							<td>Item Quantity</td>
							<td>{{ selDispatch.assets ? selDispatch.assets.length : 0 }}</td>
							<td>Item Quantity</td>
							<td>{{ selDispatch.actualAssets ? selDispatch.actualAssets.length : 0 }}</td>
						</tr>
					</table>

					<table class="table-remarks">
						<tr>
							<td>Remarks:</td>
							<td>Remarks:</td>
						</tr>
					</table>
				</b-col>
			</b-row>

			<b-row class="my-4">
				<b-col sm="6">
					<b-row class="mt-0">
						<b-col sm="4" class="form-sign-field"> Dispatched by: </b-col>
						<b-col sm="7" >
							<b-row>
								<b-col class="form-sign-value">
									{{ getNameDisplay(deployedByUser) }}
								</b-col>
							</b-row>
							<b-row>
								<b-col class="form-sign-label">
									(Signature over Printed Name)
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</b-col>

				<b-col sm="6">
					<b-row class="mt-0">
						<b-col sm="4" class="form-sign-field"> Received by: </b-col>
						<b-col sm="7" >
							<b-row>
								<b-col class="form-sign-value">
									{{ getNameDisplay(receivedByUser) }}
								</b-col>
							</b-row>
							<b-row>
								<b-col class="form-sign-label">
									(Signature over Printed Name)
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</b-col>
			</b-row>

			<b-row class="mt-4 mb-2 footer">
				<b-col sm="3" align="left">
					<img
						src="img/tawitech_logo_white.png"
						width="auto"
						height="35"
					/>
				</b-col>
				
				<b-col class="footer-col" sm="9" align="right">
					Diezmo Rd., Brgy., Pulo, Cabuyao, Laguna, Philippines, 4025 | 02 519
					4042 | 0917 869 8576
				</b-col>
			</b-row>
		</b-container>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { DispatchUtil } from '@/utils/dispatchUtil';

// DAO
import userDAO from '@/database/users';
import storageLocationDAO from '@/database/storageLocations';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import html2canvas from 'html2canvas';

export default {
	name: 'print-dispatch-summary',
	components: {
		Loading,
	},
	data() {
		return {
			selDispatch: {},
			sourceLocation: {},
			destinationLocation: {},
			deployedByUser: {},
			receivedByUser: {},
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		title() {
			return 'Print Dispatch ' + this.selDispatch.dispatchId;
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
	},
	mounted() {
		EventBus.$on('onUpdateSelDispatch', async (selDispatch) => {
			if (!_.isEmpty(selDispatch)) {
				this.selDispatch = DispatchUtil.cleanupFields(selDispatch);
			}

			await this.retrieveOtherDetails(selDispatch).then((results) => {
				this.sourceLocation = results[0];
				this.destinationLocation = results[1];

				if (results[2]) {
					this.deployedByUser = results[2];
				}

				if (results[3]) {
					this.receivedByUser = results[3];
				}

				// Check for loader
				this.isLoading = false;
			});
		});
	},
	methods: {
		getNameDisplay(user) {
			if (!_.isEmpty(user)) {
				return user.firstName + ' ' + user.lastName;
			}
			return '';
		},
		async retrieveOtherDetails(selDispatch) {
			// Show loader
			this.isLoading = true;

			this.sourceLocation = await storageLocationDAO.getStorageLocationById(
				selDispatch.source.storageLocationId
			);
			this.destinationLocation =
				await storageLocationDAO.getStorageLocationById(
					selDispatch.destination.storageLocationId
				);

			if (selDispatch.deployedBy && selDispatch.deployedBy.length > 0) {
				this.deployedByUser = await userDAO.getUserById(selDispatch.deployedBy);
			}

			if (selDispatch.receivedBy && selDispatch.receivedBy.length > 0) {
				this.receivedByUser = await userDAO.getUserById(selDispatch.receivedBy);
			}

			// Check for loader
			this.isLoading = false;
		},
		showFormattedDate(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		getFileName() {
			let sourceCompany = this.selDispatch.source.company;
			let sourceLocation = this.selDispatch.source.storageLocation;
			let source = sourceCompany + '-' + sourceLocation;

			let destinationCompany = this.selDispatch.destination.company;
			let destinationLocation = this.selDispatch.destination.storageLocation;
			let destination = destinationCompany + '-' + destinationLocation;

			let dispatchId = this.selDispatch.dispatchId;

			return dispatchId + '_' + source + '_' + destination + '.png';
		},
		async handleDownload(evt) {
			// Prevent modal from closing
			evt.preventDefault();
			// Show loader
			this.isLoading = true;

			window.scroll(0, 0);

			let filename = this.getFileName();
			let canvas = await html2canvas(this.$refs.dispatchSummary);

			const link = document.createElement('a');
			link.setAttribute('download', filename);
			link.setAttribute(
				'href',
				canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
			);
			link.click();

			this.$refs.modal.hide();
			// Hide loader
			this.isLoading = false;
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelDispatch');
	},
};
</script>

<style scoped>
.img-logo {
	margin-left: -30px;
	width: 32%;
}

.dispatch-header {
	text-transform: uppercase;
	color: #F18F01;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	line-height: 16px;
}

.dispatch-id {
	text-transform: uppercase;
	color: #122c91;
	font-weight: 700;
	font-size: 18px;
	line-height: 20px;
	align-items: center;
	margin-top: 6px;
}

.dispatch-date {
	color: #484554;
	font-weight: 400;
	font-size: 18px;
	line-height: 20px;
	align-items: center;
	margin-top: 2px;
}

.footer {
	font-size: 12px;
	text-transform: capitalize;
	margin-top: 40px;
	color: white;
	background-color: #122c91;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}

.footer-col {
	padding-top: 0.7em;
}

.section-header {
	font-size: 13px;
	margin-top: 11px;
	line-height: 9px;
	color: #122c91;
	font-weight: bold;
	text-transform: uppercase;
	text-align: left !important;
}

.scan-to-view {
	font-weight: bold;
	text-transform: uppercase;
	vertical-align: bottom;
}

.form-field {
	font-size: 14px;
	text-align: right;
	text-transform: uppercase;
	line-height: 15px;
	color: #484554;
}

.form-value {
	font-size: 14px;
	font-weight: bold;
	text-align: left;
	text-transform: uppercase;
	line-height: 15px;
	color: #484554;
}

.form-sign-field {
	font-size: 14px;
	text-transform: uppercase;
	line-height: 15px;
	color: #484554;
}

.form-sign-value {
	font-size: 14px;
	text-align: center;
	line-height: 15px;
	color: #484554;
	font-weight: bold;
	text-transform: uppercase;
	border-bottom: 1px solid #D2D5D8;
}

.form-sign-label {
	font-size: 12px;
	text-align: center;
	color: #484554;
}

th {
	border: 1px solid #D2D5D8;
	text-align: center;
	padding: 0.5em;
	color: #122c91;
	font-weight: bold;
	text-transform: uppercase;
}



table, td {
	table-layout: fixed ;
  	width: 100% ;
	border: 1px solid #D2D5D8;
	padding: 0.5em;
}

.table-remarks {
	margin-top: 1em;
}

.table-remarks > tr > td {
	vertical-align: top;
	height: 6em;
}


</style>

