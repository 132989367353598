import { db } from '@/config/firebase';


async function getStorageLocationById(locaId) {
    const dbRef = db.collection('storageLocations').doc(locaId);
    const querySnapshot = await dbRef.get();

    let storageLocation = {};
    storageLocation = {
        id: querySnapshot.id,
        ...querySnapshot.data()
    }

    return storageLocation
}

async function getStorageLocationsByCompanyId(companyId) {
    const queryRef = db.collection('storageLocations').where("companyId", "==", param.companyId);
    return queryRef.get().then(function (querySnapshot) {
        let storageLocations = {}
        querySnapshot.forEach(function (doc) {
            let storageLocation = doc.data();
            storageLocation['id'] = doc.id;
            storageLocations[doc.id] = storageLocation;
        });
        return storageLocations;
    }).catch((error) => {
        return error;
    })
}

export default {
    getStorageLocationById,
    getStorageLocationsByCompanyId
}