<template>
	<b-card>
		<b-tabs content-class="mt-3">
			<b-tab title="Primary Details" active>
				<b-card-text>
					<b-form-group label="Source:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<em>
							{{ sourceCompany }}
						</em>
						&nbsp;&nbsp;
						<strong>
							<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Map Location'"
								@click.stop="showDispatchLocation(row.item, 'source')" variant="success" class="mr-1">
								<em class="fa fa-map-marker fa-lg"></em>
							</b-button>
						</strong>
						{{ getCoordinates(row.item.source.geoaddress) }}
					</b-form-group>

					<b-form-group label="Destination:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<em>
							{{ destinationCompany }}
						</em>
						&nbsp;&nbsp;
						<strong>
							<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Map Location'"
								@click.stop="showDispatchLocation(row.item, 'destination')" variant="success" class="mr-1">
								<em class="fa fa-map-marker fa-lg"></em>
							</b-button>
						</strong>
						{{ getCoordinates(row.item.destination.geoaddress) }}
					</b-form-group>

					<b-form-group label="Notes:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<div v-if="row.item.notes">
							<div :ref="'div' + row.item.dispatchId" :style="{ width: '400px' }" class="truncate">
								{{ row.item.notes }}
							</div>
							<a v-if="isEllipsisActive('div' + row.item.dispatchId)" :ref="'a' + row.item.dispatchId"
								href="#" @click.prevent="
									toggleNotes(
										'div' + row.item.dispatchId,
										'a' + row.item.dispatchId
									)
								">
								See more
							</a>
						</div>
					</b-form-group>

					<b-form-group label="Date Created:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ showFormattedDate(row.item.dateCreated) }}
					</b-form-group>
					<b-form-group label="Created By:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ getUserDisplay(row.item.createdBy) }}
					</b-form-group>

					<b-form-group label="Date Updated:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ showFormattedDate(row.item.dateUpdated) }}
					</b-form-group>
					<b-form-group label="Updated By:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ getUserDisplay(row.item.updatedBy) }}
					</b-form-group>

					<b-form-group v-show="row.item.status === 'Cancelled'" label="Date Cancelled:" label-cols-sm="3"
						label-class="font-weight-bold pt-0" label-align-sm="right">
						{{ showFormattedDate(row.item.dateCancelled) }}
					</b-form-group>
					<b-form-group v-show="row.item.status === 'Cancelled'" label="Cancelled By:" label-cols-sm="3"
						label-class="font-weight-bold pt-0" label-align-sm="right">
						{{ getUserDisplay(row.item.cancelledBy) }}
					</b-form-group>

					<b-form-group v-show="row.item.status === 'Received'" label="Received By:" label-cols-sm="3"
						label-class="font-weight-bold pt-0" label-align-sm="right">
						{{ showFormattedDate(row.item.dateReceived) }}
					</b-form-group>
					<b-form-group v-show="row.item.status === 'Received'" label="Date Received:" label-cols-sm="3"
						label-class="font-weight-bold pt-0" label-align-sm="right">
						{{ getUserDisplay(row.item.receivedBy) }}
					</b-form-group>
				</b-card-text>
			</b-tab>

			<b-tab title="Transportation">
				<b-card-text>
					<b-row class="mb-2">
						<b-col sm="7">
							<b-form-group label="Plate No/CR/OR:" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ row.item.transportation.plateNo }}
							</b-form-group>

							<b-form-group label="Company:" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ row.item.transportation.company }}
							</b-form-group>

							<b-form-group label="Driver:" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ row.item.driver.name }}
							</b-form-group>

							<b-form-group label="Assistants:" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ row.item.driver.assistants }}
							</b-form-group>
						</b-col>
						<b-col sm="3" v-show="hasDriversLicense(row.item.driver.licenseUrl)">
							<b-img v-img-orientation-changer :src="row.item.driver.licenseUrl" alt="Responsive image"
								thumbnail fluid />
							<a href="#" class="pull-left" @click.prevent="openDriversLicenseImage(row.item.driver)">
								Click to view full image
							</a>
						</b-col>
					</b-row>
				</b-card-text>
			</b-tab>

			<b-tab title="Source Scanners">
				<b-row class="mb-2">
					<b-col sm="3" class="text-sm-left">
						<b>Email</b>
					</b-col>
					<b-col sm="2" class="text-sm-left">
						<b>Name</b>
					</b-col>
					<b-col sm="2" class="text-sm-left">
						<b>Total Scanned</b>
					</b-col>
					<b-col sm="3" class="text-sm-center">
						<b>Actual Asset</b>
					</b-col>
				</b-row>
				<b-row class="mb-2" v-bind:key="scanner.id" v-for="scanner in row.item.sourceScanners">
					<b-col sm="3" class="text-sm-left">{{ scanner.id }}</b-col>
					<b-col sm="2" class="text-sm-left">{{ scanner.name }}</b-col>
					<b-col sm="2" class="text-sm-left">{{
						scanner.scannedAssets.length
					}}</b-col>
					<b-col sm="2" class="text-sm-center">
						<b-button size="sm" @click="showScannedAssets(scanner)"
							v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="success" class="mr-1">
							<i class="fa fa-eye"></i>
						</b-button>
					</b-col>
				</b-row>
			</b-tab>

			<b-tab title="Destination Scanners">
				<b-row class="mb-2">
					<b-col sm="3" class="text-sm-left">
						<b>Email</b>
					</b-col>
					<b-col sm="2" class="text-sm-left">
						<b>Name</b>
					</b-col>
					<b-col sm="2" class="text-sm-left">
						<b>Total Scanned</b>
					</b-col>
					<b-col sm="2" class="text-sm-center">
						<b>Actual Asset</b>
					</b-col>
				</b-row>
				<b-row class="mb-2" v-bind:key="scanner.id" v-for="scanner in row.item.destinationScanners">
					<b-col sm="3" class="text-sm-left">{{ scanner.id }}</b-col>
					<b-col sm="2" class="text-sm-left">{{ scanner.name }}</b-col>
					<b-col sm="2" class="text-sm-left">{{
						scanner.scannedAssets.length
					}}</b-col>
					<b-col sm="2" class="text-sm-center">
						<b-button size="sm" @click="showScannedAssets(scanner)"
							v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="success" class="mr-1">
							<i class="fa fa-eye"></i>
						</b-button>
					</b-col>
				</b-row>
			</b-tab>

			<b-tab title="Assets">
				<b-row class="mb-2">
					<b-col sm="6">
						<b-card sub-title="Expected Assets" class="text-wrap">
							<p class="card-text">Total: {{ expectedAssets.total }}</p>

							<div class="mt-3">
								Scanned Assets: {{ expectedAssets.scan.length }}
							</div>
							<div>
								<i class="text-secondary" v-if="expectedAssets.scan.length === 0">No scanned assets.</i>
								<div :ref="'divExpectedAssetScan' + row.item.dispatchId" class="truncate"
									:style="{ width: '100%' }">
									<span :key="index" v-for="(asset, index) in expectedAssets.scan">
										<b-badge pill variant="primary">{{ asset }}</b-badge>&nbsp;
									</span>
								</div>
								<div v-if="expectedAssets.scan.length > 6">
									<a :ref="'aExpectedAssetManual' + row.item.dispatchId" href="#" @click.prevent="
										toggleNotes(
											'divExpectedAssetScan' + row.item.dispatchId,
											'aExpectedAssetManual' + row.item.dispatchId
										)
									">See more</a>
								</div>
							</div>

							<div class="mt-3">
								Manually Inputted Assets: {{ expectedAssets.manual.length }}
							</div>
							<div>
								<i class="text-secondary" v-if="expectedAssets.manual.length === 0">No manually inputted
									assets.</i>
								<div :ref="'divExpectedAssetManual' + row.item.dispatchId" class="truncate"
									:style="{ width: '100%' }">
									<span :key="index" v-for="(asset, index) in expectedAssets.manual">
										<b-badge pill variant="primary">{{ asset }}</b-badge>&nbsp;
									</span>
								</div>
								<div v-if="expectedAssets.manual.length > 6">
									<a :ref="'aExpectedAssetManual' + row.item.dispatchId" href="#" @click.prevent="
										toggleNotes(
											'divExpectedAssetManual' + row.item.dispatchId,
											'aExpectedAssetManual' + row.item.dispatchId
										)
									">See more</a>
								</div>
							</div>

							<div class="mt-3">
								Auto-added Assets: {{ expectedAssets.auto.length }}
							</div>
							<div>
								<i class="text-secondary" v-if="expectedAssets.auto.length === 0">No auto-added
									assets.</i>
								<span :key="index" v-for="(asset, index) in expectedAssets.auto">
									<b-badge pill variant="primary">{{ asset }}</b-badge>&nbsp;
								</span>
							</div>
						</b-card>
					</b-col>

					<b-col sm="6">
						<b-card sub-title="Actual Assets" class="text-wrap">
							<p class="card-text">Total: {{ actualAssets.total }}</p>

							<div class="mt-3">
								Scanned Assets: {{ actualAssets.scan.length }}
							</div>
							<div>
								<i class="text-secondary" v-if="actualAssets.scan.length === 0">No scanned assets.</i>
								<div :ref="'divActualAssetScan' + row.item.dispatchId" class="truncate"
									:style="{ width: '100%' }">
									<span :key="index" v-for="(asset, index) in actualAssets.scan">
										<b-badge pill variant="primary">{{ asset }}</b-badge>&nbsp;
									</span>
								</div>
								<div v-if="actualAssets.scan.length > 6">
									<a :ref="'aActualAssetScan' + row.item.dispatchId" href="#" @click.prevent="
										toggleNotes(
											'divActualAssetScan' + row.item.dispatchId,
											'aActualAssetScan' + row.item.dispatchId
										)
									">See more</a>
								</div>
							</div>

							<div class="mt-3">
								Manually Inputted Assets: {{ actualAssets.manual.length }}
							</div>
							<div>
								<i class="text-secondary" v-if="actualAssets.manual.length === 0">No manually inputted
									assets.</i>
								<div :ref="'divActualAssetManual' + row.item.dispatchId" class="truncate"
									:style="{ width: '100%' }">
									<span :key="index" v-for="(asset, index) in actualAssets.manual">
										<b-badge pill variant="primary">{{ asset }}</b-badge>&nbsp;
									</span>
								</div>
								<div v-if="actualAssets.manual.length > 6">
									<a :ref="'aActualAssetManual' + row.item.dispatchId" href="#" @click.prevent="
										toggleNotes(
											'divActualAssetManual' + row.item.dispatchId,
											'aActualAssetManual' + row.item.dispatchId
										)
									">See more</a>
								</div>
							</div>

							<div class="mt-3">
								Auto-added Assets: {{ actualAssets.auto.length }}
							</div>
							<div :ref="'divActualAssetAuto' + row.item.dispatchId" class="truncate"
								:style="{ width: '100%' }">
								<i class="text-secondary" v-if="actualAssets.auto.length === 0">No auto-added
									assets.</i>
								<span :key="index" v-for="(asset, index) in actualAssets.auto">
									<b-badge pill variant="primary">{{ asset }}</b-badge>&nbsp;
								</span>
							</div>
							<div v-if="actualAssets.auto.length > 6">
								<a :ref="'aActualAssetAuto' + row.item.dispatchId" href="#" @click.prevent="
									toggleNotes(
										'divActualAssetAuto' + row.item.dispatchId,
										'aActualAssetAuto' + row.item.dispatchId
									)
								">See more</a>
							</div>
						</b-card>
					</b-col>
				</b-row>

				<b-row class="mb-2">
					<b-col sm="6">
						<b-card sub-title="Damaged Assets">
							<p class="card-text">
								Total:
								{{ damagedAssets ? damagedAssets.length : 0 }}
							</p>
							<div>
								<i class="text-secondary" v-if="damagedAssets.length === 0">No damaged assets.</i>
								<div :ref="'divActualAssetDamaged' + row.item.dispatchId" class="truncate"
									:style="{ width: '100%' }">
									<span :key="index" v-for="(asset, index) in damagedAssets">
										<b-badge pill variant="primary">{{ asset }}</b-badge>&nbsp;
									</span>
								</div>
								<div v-if="damagedAssets.length > 6">
									<a :ref="'aActualAssetDamaged' + row.item.dispatchId" href="#" @click.prevent="
										toggleNotes(
											'divActualAssetDamaged' + row.item.dispatchId,
											'aActualAssetDamaged' + row.item.dispatchId
										)
									">See more</a>
								</div>
							</div>
						</b-card>
					</b-col>
				</b-row>
			</b-tab>

			<b-tab title="Proof of Details" v-if="
				row.item.toInactiveNode == 'true' && row.item.proofOfReceipt != null
			">
				<b-row class="mb-2">
					<b-col sm="3">
						<b-img v-img-orientation-changer :src="row.item.proofOfReceipt.imgUrl" alt="Responsive image"
							thumbnail fluid />
						<a href="#" class="pull-right" @click.prevent="openImage(row.item.proofOfReceipt.imgUrl)">
							Click to view full image
						</a>
					</b-col>
					<b-col sm="9">
						<b-row v-if="geoaddress" class="mb-2">
							<b-col sm="3" class="text-sm-right">
								<b>Coordinates:</b>
							</b-col>
							<b-col>
								<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Map Location'" @click.stop="
									showCapturedImageLocation(
										row.item.proofOfReceipt.geoaddress
									)
								" variant="success" class="mr-1">
									<i class="fa fa-map-marker fa-lg"></i>
								</b-button>

								{{ getCoordinates(row.item.proofOfReceipt.geoaddress) }}
							</b-col>
						</b-row>
						<b-row class="mb-2">
							<b-col sm="3" class="text-sm-right">
								<b>Recipient Name:</b>
							</b-col>
							<b-col>{{
								getDisplayValue(row.item.proofOfReceipt.recipientName)
							}}</b-col>
						</b-row>
						<b-row class="mb-2">
							<b-col sm="3" class="text-sm-right">
								<b>Approved By:</b>
							</b-col>
							<b-col>{{
								getDisplayValue(row.item.proofOfReceipt.approvedBy)
							}}</b-col>
						</b-row>
						<b-row class="mb-2">
							<b-col sm="3" class="text-sm-right">
								<b>Date Approved:</b>
							</b-col>
							<b-col>{{
								showFormattedDate(row.item.proofOfReceipt.dateApproved)
							}}</b-col>
						</b-row>
						<b-row class="mb-2">
							<b-col sm="3" class="text-sm-right">
								<b>Notes:</b>
							</b-col>
							<b-col sm="4">
								<div v-if="row.item.proofOfReceipt.notes">
									<div :ref="'div' + row.item.id" :style="{ width: '150px' }" class="truncate">
										{{ row.item.proofOfReceipt.notes }}
									</div>
									<a v-if="isEllipsisActive('div' + row.item.id)" :ref="'a' + row.item.id" href="#"
										@click.prevent="
											toggleNotes('div' + row.item.id, 'a' + row.item.id)
										">See more</a>
								</div>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-tab>
		</b-tabs>

		<b-row class="mt-4 mb-2">
			<b-col sm="12" class="text-sm-right">
				<b-button size="sm" @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover.top="'Hide Details'">
					<i class="icon-arrow-up"></i>
				</b-button>
			</b-col>
		</b-row>
		<InvalidImageDialog />
	</b-card>
</template>

<script>
// Component
import InvalidImageDialog from './InvalidImageDialog';

// Util
import { DateUtil } from '@/utils/dateutil';
import { ValidationUtil } from '@/utils/validationUtil';
import { LocationUtil } from '@/utils/locationUtil';
import { DispatchUtil } from '@/utils/dispatchUtil';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'dispatch-details-view',
	props: {
		row: {
			type: Object,
			required: true,
		},
		allUsersObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			scanner: {},
		};
	},
	components: {
		Loading,
		InvalidImageDialog,
	},
	computed: {
		sourceCompany() {
			return DispatchUtil.getCompanyLocationDisplay(this.row.item.source);
		},
		destinationCompany() {
			return DispatchUtil.getCompanyLocationDisplay(this.row.item.destination);
		},
		geoaddress() {
			if (this.row.item.proofOfReceipt)
				return this.row.item.proofOfReceipt.geoaddress;
			else return {};
		},
		expectedAssets() {
			let dispatch = this.row.item.inputAssetLog.dispatch;
			let manual = dispatch.manual ? dispatch.manual : [];
			let scan = dispatch.scan ? dispatch.scan : [];
			let auto = dispatch.auto ? dispatch.auto : [];

			// filter addedExpectedAssets from the list
			let addedExpectedAssets = this.row.item.addedExpectedAssets;
			if (!_.isEmpty(addedExpectedAssets)) {
				manual = _.difference(manual, addedExpectedAssets);
				scan = _.difference(scan, addedExpectedAssets);
				auto = _.difference(auto, addedExpectedAssets);
			}
			return {
				total: manual.length + scan.length + auto.length,
				manual,
				scan,
				auto,
			};
		},
		actualAssets() {
			let receipt = this.row.item.inputAssetLog.receipt;
			const manual = receipt.manual ? receipt.manual : [];
			const scan = receipt.scan ? receipt.scan : [];
			const auto = receipt.auto ? receipt.auto : [];
			return {
				total: manual.length + scan.length + auto.length,
				manual,
				scan,
				auto,
			};
		},
		damagedAssets() {
			return this.row.item.damagedAssets ? this.row.item.damagedAssets : [];
		}
	},
	mounted() {
	},
	methods: {
		getUserDisplay(userId) {
			let userObj = this.allUsersObj[userId];

			if (!_.isEmpty(userObj)) {
				return userObj.firstName + ' ' + userObj.lastName + ' (' + userId + ')';
			}
			return userId;
		},

		getCoordinates(geoaddress) {
			geoaddress = LocationUtil.getGeoaddress(geoaddress);

			return (
				'(' +
				geoaddress.latitude.toFixed(6) +
				', ' +
				geoaddress.longitude.toFixed(6) +
				')'
			);
		},
		showFormattedDate(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		getDisplayValue(value) {
			if (!value || value.length === 0) {
				return '-';
			}
			return value;
		},
		showScannedAssets(scanner) {
			this.scanner = scanner;

			let selDispatch = this.row.item;
			let sourceScanners = selDispatch.sourceScanners;
			let destinationScanners = selDispatch.destinationScanners;

			let inputAssetLog = {};

			if (sourceScanners.indexOf(scanner) > -1) {
				inputAssetLog = selDispatch.inputAssetLog.dispatch;
			} else if (destinationScanners.indexOf(scanner) > -1) {
				inputAssetLog = selDispatch.inputAssetLog.receipt;
			}

			let scan = [];
			let manual = [];
			let auto = [];

			if (Object.keys(inputAssetLog).length !== 0) {
				this.scanner.scannedAssets.forEach((asset) => {
					if (inputAssetLog.scan) {
						for (const assetScanned of inputAssetLog.scan) {
							if (asset === assetScanned) {
								scan.push(assetScanned);
								break;
							}
						}
					}
					if (inputAssetLog.manual) {
						for (const assetManual of inputAssetLog.manual) {
							if (asset === assetManual) {
								manual.push(assetManual);
								break;
							}
						}
					}
					if (inputAssetLog.auto) {
						for (const autoAddedAssets of inputAssetLog.auto) {
							if (asset === autoAddedAssets) {
								auto.push(autoAddedAssets);
								break;
							}
						}
					}
				});
			}

			this.scanner.inputAssetLog = {
				scan,
				manual,
				auto,
			};

			EventBus.$emit('onUpdateSelScannedAssetsView', this.scanner);
			this.$bvModal.show('scanned-assets-view');
		},
		showDispatchLocation(details, endpoint) {
			const params = {
				source: details.source,
				destination: details.destination,
				endpoint,
			};

			EventBus.$emit('onUpdateSelDispatchLocationView', params);
			this.$bvModal.show('dispatch-location-view');
		},
		openImage(imageLink) {
			EventBus.$emit('onUpdateSelProofReceiptImageView', {
				dispatchId: this.row.item.dispatchId,
				imageLink: imageLink,
				latitude: this.geoaddress.latitude ? this.geoaddress.latitude : 0.0,
				longitude: this.geoaddress.longitude ? this.geoaddress.latitude : 0.0,
				dateApproved: this.row.item.proofOfReceipt
					? this.row.item.proofOfReceipt.dateApproved
					: '',
			});
			this.$bvModal.show('receipt-image-view');
		},
		openDamagedAssetView(damagedAsset) {
			if (ValidationUtil.isValidURL(damagedAsset.imgUrl)) {
				EventBus.$emit('onUpdateSelDamagedAssetImage', damagedAsset);
				this.$bvModal.show('damaged-asset-image-view');
			} else {
				this.$bvModal.show('invalid-image-dialog');
			}
		},
		showCapturedImageLocation() {
			EventBus.$emit(
				'onUpdateSelProofReceiptImageLocationView',
				this.geoaddress
			);
			this.$bvModal.show('proof-receipt-image-location-view');
		},
		toggleNotes(divRefId, aRefId) {
			let elementArray = this.$refs[divRefId];
			if (elementArray) {
				if (elementArray instanceof Array) {
					let className = elementArray[0].className;
					if (className === 'truncate') {
						this.$refs[divRefId][0].className = null;
						this.$refs[aRefId][0].text = 'See less';
					} else {
						this.$refs[divRefId][0].className = 'truncate';
						this.$refs[aRefId][0].text = 'See more';
					}
				} else {
					let className = elementArray.className;
					if (className === 'truncate') {
						this.$refs[divRefId].className = null;
						this.$refs[aRefId].text = 'See less';
					} else {
						this.$refs[divRefId].className = 'truncate';
						this.$refs[aRefId].text = 'See more';
					}
				}
			}
		},
		isEllipsisActive(divRefId) {
			let elementArray = this.$refs[divRefId];
			if (elementArray) {
				if (elementArray instanceof Array) {
					let element = elementArray[0];
					return element.offsetWidth < element.scrollWidth;
				} else {
					return elementArray.offsetWidth < elementArray.scrollWidth;
				}
			}
			return false;
		},

		openDriversLicenseImage(driver) {
			EventBus.$emit('onSelDriversLicenseImageView', {
				driver: driver,
			});
			this.$bvModal.show('driver-license-image-view');
		},

		hasDriversLicense(licenseUrl) {
			return licenseUrl && !_.isEmpty(licenseUrl);
		},
	},
};
</script>

<style scoped></style>



